import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Img from "gatsby-image"

import Container from "../components/Container"

export default function GamePostCarousel({ data, pageContext }) {
  const { markdownRemark: post } = data
  const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  const { next } = pageContext
  setInterval(() => {
    window.location = next.frontmatter.carouselpath
  }, 30000)

  return (
    <Layout>
      <Container>
        <div id={post.frontmatter.id} className=" row bg-dark text-white games">
          <div className="col-md-12">
            <Img
              key="gameImg"
              className="thumbnail margin-bottom-40"
              fluid={featuredImgFluid}
            />
          </div>
          {/* /.col */}
          <div className="col-md-8 margin-bottom-20">
            <h1 className="no-margin-top">{post.frontmatter.title}</h1>
            <ul>
              <li>
                <strong>Kategorie:</strong> {post.frontmatter.kategorie}
              </li>
              <li>
                <strong>Fokus:</strong> {post.frontmatter.fokus}
              </li>
            </ul>
            <p>{post.frontmatter.description}</p>
          </div>
          {/* /.col */}
          <div className="col-md-4 margin-bottom-20 padding-left-md-40">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query CarouselPath($path: String!) {
    markdownRemark(frontmatter: { carouselpath: { eq: $path } }) {
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        kategorie
        fokus
        description
        id
      }
    }
  }
`
